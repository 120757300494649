<template>
  <div v-if="visible" :class="'resource-share-post ' + (loaded?'':'not-load')">
    <div class="shade" @click="hide"></div>
    <div style="z-index: 102" class="body">
      <img @load="onLoad" :src="src" style="width: 80vw;"/>
    </div>
  </div>

</template>


-------------------- script --------------------

<script>
  import axios from 'axios';
  import {copyToClipBoard} from "../config/util";

  export default {
    props: {
      rankType: {
        type: String
      },
      dayInRange: {
        type: Number
      }
    },
    data() {
      return {
        src: '',
        visible: false,
        loaded: false
      }
    },


    methods: {


      async show() {
        this.$loading('生成中');
        this.visible = true;
        const token = localStorage.getItem('token');
        const {rankType, dayInRange} = this;
        this.src = `/api/web/v2/search-svr/userRankSharePost/post.jpg?userRankType=${rankType}&ts=${Date.now()}&Token=${token}&daysInRange=${dayInRange}&applet=h5&url=https://m.001ppt.com/rank`;
      },


      hide() {
        this.visible = false;
        this.src = '';
      },

      onLoad() {
        setTimeout(() => {
          this.$loading.close();
        }, 300);
        this.loaded = true;
      }
    }
  }
</script>


-------------------- style --------------------

<style scoped lang="less">
  .shade {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #151d36;
    opacity: .9;
    z-index: 101;
  }


  .resource-share-post {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }


  .resource-share-post image {
    border-radius: 16px;
    width: 80vw;
    z-index: 1000;
  }


  .step {
    color: #fff;
    font-size: 14px;
    margin-top: 16px;
    display: flex;
    align-items: center;
  }

  .wx-btn {
    padding: 4px 8px;
    color: #fff;
    background: #5fb6e2;
    margin-left: 4px;
    border-radius: 4px;
  }

  .body {
    transform: scale(1);
    transition: transform .3s ease-in-out;
  }

  .not-load {
    opacity: 0;

    .body {
      transform: scale(0);
      transition: transform .3s ease-in-out;
    }
  }

</style>
