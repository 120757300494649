<template>
  <div class="rank-item" :class="{'last-rank-item':isLast}">
    <div class="rank-item-left" @click="goUser">
      <div :class="'rank-item-index rank-item-index-'+index">{{index>100000?'10w+':index}}</div>
      <div class="rank-item-avatar">
        <img :src="innerItem.avatar" alt="" v-if="innerItem.avatar">
        <img src="https://cdn.001ppt.cn/wxApp/assets/images/no_avatar.png" v-else>
        <img class="vip-right-icon" v-if="getVipIcon(innerItem.vipRightId)" :src="getVipIcon(innerItem.vipRightId)">
      </div>
      <div class="rank-item-nickName">
        <div>{{innerItem.nickName}}</div>
        <div class="rank-item-beat-rate" v-if="innerItem.beatRate">
          已击败全国{{moneyFilter(innerItem.beatRate?(innerItem.beatRate*100):0)}}%用户
        </div>
      </div>
    </div>
    <div class="rank-item-right">
      <div class="rank-item-value">
        {{innerItem.count || 0}}{{unit}}
      </div>
      <div class="rank-item-thumb" @click="toggleThumb">
        <i class="iconfont icon-like_active" v-if="innerItem.hasThumb"></i>
        <i class="iconfont icon-like" v-else></i>
        <div class="rank-item-thumb-value" v-if="innerItem.thumbCount>0">{{innerItem.thumbCount}}</div>
      </div>
    </div>
  </div>
</template>


-------------------- script --------------------

<script>


import {userThumbV2Api} from "../../../api/v2/userThumbV2Api";

export default {
  props: {
    item: {
      type: Object
    },
    index: {
      type: Number
    },
    unit: {
      type: String
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },

  watch:{
    item:{
      immediate: true,
      handler(){
        this.innerItem = this.item || {}
      }
    }
  },

  data() {
    return {
      innerItem: {}
    }
  },

  methods: {

    goUser() {
      this.$router.push(`/otherPerson/${this.item.userId}`);
    },


    async toggleThumb() {
      const res = await userThumbV2Api.toggleThumb({toUserId: this.item.userId});
      if (res) {
        this.$toast.success('点赞成功')
      } else {
        this.$toast.success('取消点赞成功')
      }
      const item = {...this.item};
      const delta = res ? 1 : -1;
      item.hasThumb = res;
      item.thumbCount += delta;

      this.innerItem.hasThumb = res
      this.innerItem.thumbCount += delta

      // this.$emit('update:item', item);
    }

  }
}
</script>


-------------------- style --------------------

<style scoped lang="less">
.rank-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin: 20px 20px 20px 15px;
  padding: 0 20px;
}

.last-rank-item {
  margin-bottom: 0;
}

.rank-item-left, .rank-item-right {
  display: flex;
  align-items: center;
}

.rank-item-index {
  margin-right: 14px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
}

.rank-item-index-1 {
  border: 3px solid #E44C4C;
  font-weight: bolder;
}

.rank-item-index-2 {
  border: 3px solid #fc744c;
  font-weight: bolder;
}

.rank-item-index-3 {
  border: 3px solid #fcc44c;
  font-weight: bolder;
}

.rank-item-avatar {
  display: flex;
  align-items: center;
  position: relative;
}

.rank-item-avatar img {
  border-radius: 50px;
  height: 38px;
  width: 38px;
  margin-right: 9px;
  border: 1px solid #eee;
}

.rank-item-nickName {
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rank-item-value {
  margin-right: 16px;
}

.rank-item-thumb {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rank-item-thumb .iconfont {
  font-size: 18px;
  margin-bottom: 4px;
}

.icon-like_active {
  color: #e1494e;
}

.rank-item-thumb-value {
  font-size: 10px;
  opacity: .6;
}

.rank-item-beat-rate {
  color: #e1494e;
  font-size: 10px;
}

.vip-right-icon {
  position: absolute;
  width: 16px !important;
  height: 16px !important;
  right: -5px;
  bottom: 0;
}
</style>

